/*Import font*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

/*General format*/
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

/*Define colors*/
:root{
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4cb5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);

    --transition: all 400ms ease;

    --containter-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

/*Smooth scroll & no scrollbar*/
html{
    scroll-behavior: smooth;
}
::-webkit-scrollbar{
    display: none;
}


body{
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg); /*static backup for gradient animation*/
    color: var(--color-white);
    line-height: 1.7;

    /*Background animation*/
    background: 
    radial-gradient(circle at bottom, #272770, transparent 80%),    
    linear-gradient(to top, rgb(37, 58, 79) 0%, transparent 70%),    
    linear-gradient(to bottom, transparent, rgba(#3b1a82, 0.4) 70%),
    #1f1f38;  
    animation: bg 30s infinite;
}

/*Background animation*/
@keyframes bg {
    0%, 100% { background-color: #2c2c6c; }
    25% { background-color: rgb(24, 79, 134); }
    50% { background-color: #51237f; }
    75% { background-color: #45404b; }
  }

/* GENERAL STYLES */
.container{
    width: var(--containter-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5{
    font-weight: 500;
}

h1{
    font-size: 2.5rem;
}

section{
    margin-top: 9rem;
    padding-top: 50px;
    /*height: 80vh;*/
}

section > h2, section > h5{
    text-align: center;
    color: var(--color-light);
}

section > h2{
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light{
    color: var(--color-light);
}

a{
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover{
    color: var(--color-white);
}

/*Button setup*/
.btn{
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer; 
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover{
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary{
    background: var(--color-primary);
    color: var(--color-bg);
}

img{
    display: block;
    width: 100%;
    object-fit: cover;
}

/* MEDIA QUERIES (TABLETS) */

@media screen and (max-width: 1024px) {
    .container{
        width: var(--container-width-md);
    }

    section{
        margin-top: 6rem;
    }
}

/* MEDIA QUERIES (PHONES) */

@media screen and (max-width: 600px) {
    .container{
        width: var(--container-width-sm);
    }

    section > h2{
        margin-bottom: 2rem;
    }
}