header{
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}

/* Buttons */
.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* Socials */

.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after{
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* IMAGE */

.me{
    background: linear-gradient(var(--color-primary), transparent);
    width: 18rem;
    height: 29rem;
    position: absolute;
    left: calc(50% - 9rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}

/* MEDIA QUERIES (TABLETS) */
@media screen and (max-width: 1024px) {
    header{
        height: 68vh;
    }
}

/* MEDIA QUERIES (PHONES) */

@media screen and (max-width: 600px) {
    header{
        height: 100vh;
    }

    .header__socials{
        display: none;
    }
}